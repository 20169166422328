import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import {FacebookOutlined,
  TwitterOutlined,
  LinkedinOutlined,
  PhoneOutlined,
  MailOutlined} from '@ant-design/icons'
import es from '../../assets/images/brand/es.png'
import acuant from '../../assets/images/brand/acuant.png'
import b from '../../assets/images/brand/b.png'
import fb from '../../assets/images/icons/facebook.png'
import x from '../../assets/images/icons/twitter.png'
import li from '../../assets/images/icons/linkedin.png'
import yt from '../../assets/images/icons/yt.png'
import apex from '../../assets/images/logo/apex.png'
  import './footer.css'

class Footer extends Component {
  constructor(props) {
    super(props)
    const date = new Date()
    this.year = date.getFullYear()
  }
  render() {
    return (
      <div className="wd_scroll_wrap">
        <footer className="foo-bot">
          <section className='footer-main'>
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                     <div className="col-md-3">
                      <span>Connects</span>
                      <div className="social-icons-footer">
                        <a href="https://www.facebook.com/apexdefilabs" target="_blank"  rel="noopener noreferrer">
                          <img src={fb} alt="" />
                        </a>
                        <a href="https://x.com/apexdefilabs" target="_blank"  rel="noopener noreferrer">
                          <img src={x} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/company/apex-defi-labs/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Bq%2FQK840HQaGsr8zH7K43hg%3D%3D" target="_blank"  rel="noopener noreferrer">
                          <img src={li} alt="" />
                        </a>
                        <a href="https://www.youtube.com/@apexdefilabs504" target="_blank"  rel="noopener noreferrer">
                          <img src={yt} alt="" />
                        </a>
                      </div>
                     </div>
                     <div className="col-md-3">
                      <span>Newsletter</span>
                      <input type="email" class="form-control" id="exampleInputEmail1"/>
                      <button type="button" class="btn btn-dark">Subscribe</button>
                     </div>
                     <div className="col-md-3">
                      <span>Associations</span>
                      <div className="asso">
                        <img src={acuant} alt="" />
                        {/* <img src={b} alt="" /> */}
                        <img src={es} alt="" />
                      </div>
                     </div>
                     <div className="col-md-3 contact-info">
                      <span>Contact Us</span>
                      <div className="contact">
                        <PhoneOutlined /><span style={{color:'#35607C'}}>
                        <a href="tel:+18886660441">
                          +1 888-666-0441
                        </a>
                          </span>
                      </div>
                      <div className="mail">
                        <MailOutlined /><span style={{color:'#35607C'}}><a href={`mailto:info@apexdefilabs.com`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#1890ff' }}
                            >info@apexdefilabs.com</a></span>
                      </div>
                      {/* <div className="address">
                      <p style={{color:'#35607C',fontWeight:'600'}}>Apex Defi Labs Inc</p>
                      <p>244 Fifth Ave Suite 2017</p>
                      <p>New York City, New York 10001</p>
                      </div> */}
                     </div>
                  </div>
                </div>
              </div>
          </section>
          <div className="footer-bottom">
            <Container>
              <Row >
                <Col lg={5} md={5} sm={12} className="col-xs-12">
                  <div className="copyright">
                    <p>© {this.year - 1} - {this.year} <Link to="#"><span>Apex Defi Labs</span></Link> | All rights reserved.</p>
                  </div>
                </Col>
                <Col lg={2} md={2} sm={12} className='col-xs-12 footer-logo' style={{textAlign:'center'}}>
                  <img src={apex} alt="" className=''/>
                </Col>
                <Col lg={5} md={5} sm={12} className="col-xs-12" style={{float:'right'}}>
                  <div className="foo-link">
                    <ul>
                      <li  onClick={()=>window.location.href='/disclaimer'}><Link>Legal Disclaimer</Link></li>
                      <li onClick={()=>window.location.href='/terms-of-use'}><Link>Terms of Service</Link></li>
                      <li onClick={()=>window.location.href='/privacy-policy'}><Link>Privacy Policy</Link></li>
                      {/* <li><a href="/disclaimer" target="_blank" rel="noopener referer">Legal Disclaimer</a></li>
                      <li><a href="/terms-of-use" target="_blank" rel="noopener referer">Terms of Service</a></li>
                      <li><a href="/privacy-policy" target="_blank" rel="noopener referer">Privacy Policy</a></li> */}
                    </ul>
                  </div>
                </Col>
              </Row>
              <Row>
                <p className='disclaimer'>
                  <b>Disclaimer : </b>
                  Investing in digital securities involves significant risk. Digital securities are speculative investments and may not be suitable for all investors. There is no guarantee that investors will receive a return on their investment, and they may lose all or part of their capital. Investments offered under Regulation D are exempt from registration with the SEC and are available only to accredited investors as defined by the Securities Act of 1933. These investments are illiquid and may not be resold except as permitted under applicable securities laws. Prospective investors should carefully consider their financial situation and consult with a financial advisor before investing. Past performance is not indicative of future results, and there is no assurance that any investment strategy will achieve its objectives.
                </p>
              </Row>
            </Container>
          </div>
        </footer>
      </div>
    );
  }
}

export default Footer;