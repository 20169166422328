import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Table,
  Button,
  Spin,
  Result,
  notification,
  Tabs,
  Modal,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import BigNumber from 'bignumber.js';
import {
  TokenConfigurationProcess,
  SymbolDetailsAndSTData,
  TokenPhase,
  TokenPurchaseTrx,
  RegLaunched,
  TokenOfferingModel,
} from '../../Shared/interfaces';
import { AuthService } from '../../Shared/Auth.service';
import { SecurityTokenRegistryService } from '../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { MetamaskService } from '../../Shared/Metamask.service';
import { SharedService } from '../../Shared/Shared.service';
import { useHistory } from 'react-router-dom';
import { TokenConfigurationService } from '../../TokenConfigurations/TokenConfiguration.service';
import { IssuerTokenAdminService } from '../IssuerTokenAdmin.service';
import TransactionModal from '../../Shared/TransactionModal';
import moment from 'moment';
import MainFacet from '../../Shared/SecurityToken/Facets/MainFacet/index';
import WrongMetamaskWalletWarning from '../../Shared/WrongMetamaskWalletWarning';
import ERC1410Facet from "../../Shared/SecurityToken/Facets/ERC1410Facet";
import TxFeeDelegationModal from "../../Shared/TxFeeDelegationModal";

const { Title } = Typography;
const { TabPane } = Tabs;
const tokenConfigurationService = new TokenConfigurationService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const issuerTokenAdminService = new IssuerTokenAdminService();
const sharedService = new SharedService();

const mainFacet = new MainFacet();
const erc1410Facet = new ERC1410Facet();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () =>
    new MetamaskService().useSelectedWalletContext();

export default function IssueTokens() {
  const { userInfo } = useUserContext();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const history = useHistory();

  const [USDTokenPurchases, setUSDTokenPurchases] = useState<any[]>();
  const [ETHTokenPurchases, setETHTokenPurchases] =
      useState<TokenPurchaseTrx[]>();
  // const [count, setCount] = useState(0);

  // const [submitting, setSubmitting] = useState<{employesAndAffiliatesData: boolean, USDTokenPurchases: boolean}>();
  // const [transactionReceipt, setTransactionReceipt] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  const [transactions, setTransactions] = useState<
      { submitting?: boolean; receipt?: any; details: string }[]
      >([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const [USDPrice, setUSDPrice] = useState('');

  const [tokenConfigurationProcess, setTokenConfigurationProcess] =
      useState<TokenConfigurationProcess>();
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
      useState<SymbolDetailsAndSTData>();

  const [isCloseSaleModalVisible, setIsCloseSaleModalVisible] = useState(false);
  const [closingSale, setClosingSale] = useState(false);
  const [lastLaunchedReg, setLastLaunchedReg] = useState<string>('');
  const [regulationsLaunched, setRegulationsLaunched] = useState<RegLaunched[]>(
      []
  );

  const [isDelegationModalVisible, setIsDelegationModalVisible] = useState(false);

  useEffect(() => {
    (async () => {
      if (!userInfo) return;

      const _tokenConfigurationProcess: TokenConfigurationProcess = (
          await tokenConfigurationService.getLastTokenConfigurationProcess()
      ).data;
      setTokenConfigurationProcess(_tokenConfigurationProcess);

      if (!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData =
          await securityTokenRegisteryService.getSymbolDetailsAndSTData(
              _tokenConfigurationProcess.tokenSymbol
          );
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      if (!_symbolDetailsAndSTData?.symbolDetails.isDeployed)
        return setLoading(false);

      const [
        tokenPhasesResponse,
        investorsResponse,
        _ETHTokenPurchases,
        _lastLaunchedReg,
        _regulationsLaunched,
        _offeringModelsRes,
        _totalPartitions
      ] = await Promise.all([
        tokenConfigurationService.getPhasesByConfigProcessId({
          tokenConfigurationProcessId: _tokenConfigurationProcess._id,
          limit: null,
          searchPage: null,
          all: true,
        }),
        issuerTokenAdminService.getTokensToIssue(),
        issuerTokenAdminService.getETHTokensToIssue(
            _symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
        mainFacet.regulation(
            _symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
        mainFacet.getRegulationsLaunched(
            _symbolDetailsAndSTData.securityTokenData.contractAddress
        ),
        tokenConfigurationService.getOfferingModels({
          tokenConfigurationProcessId: _tokenConfigurationProcess._id as string,
          limit: null,
          searchPage: null,
          all: true,
        }),
        erc1410Facet.totalPartitions(_symbolDetailsAndSTData.securityTokenData.contractAddress)
      ]);

      console.log(_regulationsLaunched);

      const _phases: TokenPhase[] = tokenPhasesResponse.data;
      const _USDTokenPurchases: any[] = investorsResponse.data;
      const _offeringModels: TokenOfferingModel[] = _offeringModelsRes.data;

      const decimals = +(_symbolDetailsAndSTData?.securityTokenData
          .decimals as string);

      for (let index = 0; index < _ETHTokenPurchases.length; index++) {
        const _ETHTokenPurchase = _ETHTokenPurchases[index];

        const dateOfAmountReceived = +_ETHTokenPurchase['creationDate'] * 1000;

        _ETHTokenPurchase['key'] = index;
        _ETHTokenPurchase['ETHAmount'] = new BigNumber(
            _ETHTokenPurchase['ETHAmount']
        )
            .times(new BigNumber(10).pow(-18))
            .toString(10);
        _ETHTokenPurchase['USDAmount'] = new BigNumber(
            _ETHTokenPurchase['ETHAmount']
        )
            .times(380)
            .decimalPlaces(2)
            .toString(10);
        _ETHTokenPurchase['amountWithoutBonus'] = new BigNumber(
            _ETHTokenPurchase['USDAmount']
        )
            .div(_tokenConfigurationProcess.pricePerToken as number)
            .decimalPlaces(decimals)
            .toFixed();
        _ETHTokenPurchase['dateOfAmountReceived'] = dateOfAmountReceived;

        const validTokenPhase = _phases?.find(
            (phase) =>
                dateOfAmountReceived >= phase.startDate &&
                dateOfAmountReceived <= phase.endDate
        );

        console.log(validTokenPhase);

        const validBonusPercent = validTokenPhase
            ? validTokenPhase.bonusOffers.find(
            (offer) =>
                _ETHTokenPurchase['USDamount'] >=
                offer.minInvestmentAmount.min &&
                _ETHTokenPurchase['USDamount'] <= offer.minInvestmentAmount.max
        )?.bonusPercent || 0
            : 0;

        _ETHTokenPurchase['phaseName'] = validTokenPhase?.phaseName;
        _ETHTokenPurchase['bonusPercent'] = validBonusPercent;

        _ETHTokenPurchase['amount'] = new BigNumber(
            _ETHTokenPurchase['amountWithoutBonus']
        )
            .times(new BigNumber(100).plus(validBonusPercent))
            .div(100)
            .decimalPlaces(decimals)
            .toFixed();
      }

      console.log(_USDTokenPurchases);

      for (let index = 0; index < _USDTokenPurchases.length; index++) {
        const _USDTokenPurchase = _USDTokenPurchases[index];

        // const dateOfAmountReceived = new Date().getTime();
        const dateOfAmountReceived = new Date(_USDTokenPurchase.dateOfAmountReceived).getTime();

        _USDTokenPurchase['key'] = index;
        _USDTokenPurchase['firstName'] = _USDTokenPurchase.user?.firstName;
        _USDTokenPurchase['email'] = _USDTokenPurchase.user?.email;
        _USDTokenPurchase['wallet'] = _USDTokenPurchase.user?.searchAbleWallet;
        _USDTokenPurchase['USDAmount'] = new BigNumber(
            _USDTokenPurchase?.amountToInvest
        )
            .decimalPlaces(2)
            .toFixed();
        // .toNumber();
        _USDTokenPurchase['amountWithoutBonus'] = new BigNumber(
            _USDTokenPurchase?.no_of_tokens
        )
            .decimalPlaces(decimals)
            .toFixed();

        _USDTokenPurchase['dateOfAmountReceived'] = dateOfAmountReceived;

        const validTokenPhase = _phases?.find(
            (phase) =>
                dateOfAmountReceived >= phase.startDate &&
                dateOfAmountReceived <= phase.endDate &&
                new BigNumber(_USDTokenPurchase['USDAmount']).gte(
                    phase.bonusOffers[0].minInvestmentAmount.min
                ) &&
                new BigNumber(_USDTokenPurchase['USDAmount']).lte(
                    phase.bonusOffers[phase.bonusOffers.length - 1]
                        .minInvestmentAmount.max
                )
        );

        console.log(validTokenPhase);

        _USDTokenPurchase['phaseName'] = validTokenPhase?.phaseName;
        _USDTokenPurchase['modelName'] = _offeringModels.find(
            (_offeringModel) =>
                _offeringModel._id === validTokenPhase?.tokenOfferingModelId
        )?.name;

        _USDTokenPurchase['USDBonusAmount'] = validTokenPhase
            ? new BigNumber(
                validTokenPhase.bonusOffers[0].minInvestmentAmount.min
            )
                .times(validTokenPhase.bonusFloor)
                .div(100)
                .toFixed()
            : '0';

        if (validTokenPhase) {
          for (let bonusOffer of validTokenPhase.bonusOffers) {
            const USDAmountBg = new BigNumber(_USDTokenPurchase['USDAmount']);

            if (USDAmountBg.gt(bonusOffer.minInvestmentAmount.max)) {
              _USDTokenPurchase['USDBonusAmount'] = new BigNumber(
                  bonusOffer.minInvestmentAmount.max
              )
                  .minus(bonusOffer.minInvestmentAmount.min)
                  .times(bonusOffer.bonusPercent)
                  .div(100)
                  .plus(_USDTokenPurchase['USDBonusAmount'])
                  .toFixed();
            } else if (
                USDAmountBg.gte(bonusOffer.minInvestmentAmount.min) &&
                USDAmountBg.lte(bonusOffer.minInvestmentAmount.max)
            ) {
              _USDTokenPurchase['USDBonusAmount'] = new BigNumber(USDAmountBg)
                  .minus(bonusOffer.minInvestmentAmount.min)
                  .times(bonusOffer.bonusPercent)
                  .div(100)
                  .plus(_USDTokenPurchase['USDBonusAmount'])
                  .toFixed();

              break;
            }
          }
        }

        _USDTokenPurchase['bonusAmount'] = new BigNumber(
            _USDTokenPurchase['USDBonusAmount']
        )
            .times(_USDTokenPurchase['amountWithoutBonus'])
            .div(_USDTokenPurchase['USDAmount'])
            .decimalPlaces(decimals)
            .toFixed();

        _USDTokenPurchase['amount'] = new BigNumber(
            _USDTokenPurchase['amountWithoutBonus']
        )
            .plus(_USDTokenPurchase['bonusAmount'])
            .decimalPlaces(decimals)
            .toFixed();

        if (!sharedService.isEthereumAddress(_USDTokenPurchase['wallet'])) {
          _USDTokenPurchase['loadingCanTransfer'] = false;
          _USDTokenPurchase['canTransfer'] = [false, 'Enter a valid Address'];
        } else {
          const _from = '0x0000000000000000000000000000000000000000';
          const _to = _USDTokenPurchase['wallet'];
          const _value = new BigNumber(0)
              .times(new BigNumber(10).pow(decimals))
              .decimalPlaces(0)
              .toString(10);

          // const canTransfer = await securityTokenService.canTransfer(_symbolDetailsAndSTData?.securityTokenData.contractAddress as string, _from, _to, _value, _regulationsLaunched.length-1, false);
          const canTransfer = await erc1410Facet.canTransferByPartition(
              _symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
              _from,
              _to,
              _totalPartitions[_totalPartitions.length - 1],
              _value,
              // _regulationsLaunched.length - 1,
              // false
          );

          console.log('canTransfer:', canTransfer);

          _USDTokenPurchase['loadingCanTransfer'] = false;
          _USDTokenPurchase['canTransfer'] = canTransfer;
        }
      }

      console.log("_USDTokenPurchases",_USDTokenPurchases);

      setETHTokenPurchases(_ETHTokenPurchases);
      setUSDTokenPurchases(_USDTokenPurchases);
      setLastLaunchedReg(_lastLaunchedReg);
      setRegulationsLaunched(_regulationsLaunched);
      setLoading(false);
    })();
  }, [userInfo]);

  const ETHColums = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string) => (
          value ? (
              <div style={{ marginTop: '20px' }}>
                <div>
                  <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}
                  >
                    {sharedService.minifyAddress(value)}
                  </a>
                </div>
              </div>
          ) : (
              <div style={{ marginTop: '20px', color: 'orange'}}>
                <div>
                  {"Investor Needs to link wallet address"}
                </div>
              </div>
          )
      ),
    },
    {
      title: 'Original Invested Amount (ETH)',
      dataIndex: 'ETHAmount',
    },
    {
      title: 'Equivalent Invested Amount (USD)',
      dataIndex: 'USDamount',
    },
    {
      title: `Tokens to be created (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amountWithoutBonus',
    },
    {
      title: 'Phase applied',
      dataIndex: 'phaseName',
      render: (value: string) => value || 'None',
    },
    {
      title: 'Bonus',
      dataIndex: 'bonusPercent',
      render: (value: number) => `${value}%`,
    },
    {
      title: `Tokens to be created including Bonus (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amount',
    },
    {
      title: 'Date of Purchase',
      //dataIndex: 'creationTS',
      dataIndex: 'dateOfAmountReceived',
      render: (value: number) => moment(value).format('LLL'),
    },
  ];

  const investorColums = [
    {
      title: 'Name',
      dataIndex: 'firstName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Wallet Address',
      dataIndex: 'wallet',
      render: (value: string, record) => (
          <div style={{ marginTop: '20px' }}>
            {record['loadingCanTransfer'] ? (
                <LoadingOutlined />
            ) : value ? (
                <div style={{ marginTop: '20px' }}>
                  <div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${sharedService.etherscanURL[networkId as string]}/address/${value}`}
                    >
                      {sharedService.minifyAddress(value)}
                    </a>
                  </div>
                </div>
            ) : (
                <div style={{ marginTop: '20px', color: 'orange'}}>
                  <div>
                    {"Investor Needs to link wallet address"}
                  </div>
                </div>
            )}
            {/* <Input suffix={record['loadingCanTransfer']? <LoadingOutlined/> : <span />} value = {value} onChange={e => editInvestorTable(e.target.value, record, 'wallet')}/> */}
            {/* <Input.Search loading value = {value} onChange={e => editInvestorTable(e.target.value, record, 'wallet')}/> */}
            {value && !record['loadingCanTransfer'] &&
                record['canTransfer'] &&
                (record['canTransfer'][0] !== '0x51') && (
                    <span style={{ color: 'red' }}>{sharedService.bytes32ToString(record['canTransfer'][1])}</span>
                )}
          </div>
      ),
    },
    {
      title: 'Original Invested Amount (USD)',
      dataIndex: 'USDAmount',
      // render: (value, record) => <InputNumber min={0} value = {value} onChange={e => editInvestorTable(e, record, 'USDamount')}/>
    },
    {
      title: `Tokens to be created (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amountWithoutBonus',
    },
    {
      title: 'Model applied',
      dataIndex: 'modelName',
      render: (value: string) => value || 'None',
    },
    {
      title: 'Phase applied',
      dataIndex: 'phaseName',
      render: (value: string) => value || 'None',
    },
    {
      title: 'Bonus (USD)',
      dataIndex: 'USDBonusAmount',
    },
    {
      title: `Bonus (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'bonusAmount',
    },
    {
      title: `Tokens to be created including Bonus (${symbolDetailsAndSTData?.securityTokenData.symbol})`,
      dataIndex: 'amount',
    },
    {
      title: 'Date of Purchase',
      dataIndex: 'dateOfAmountReceived',
      render: (value: number) => moment(value).format('LLL'),
    },
  ];

  const selectInvestorsFromTable = (selectedRows: any[]) => {
    const newData = sharedService.clone(USDTokenPurchases);
    newData?.forEach(
        (data) =>
            (data['selected'] = selectedRows
                .map((row) => row._id)
                .includes(data._id))
    );

    setUSDTokenPurchases(newData);
  };

  const selectETHInvestorsFromTable = (selectedRows: any[]) => {
    const newData = sharedService.clone(ETHTokenPurchases);
    newData?.forEach(
        (data) =>
            (data['selected'] = selectedRows
                .map((row) => row.index)
                .includes(data.index))
    );

    setETHTokenPurchases(newData);
  };


  const openTxFeeDelegationModal = async() => {
    setIsDelegationModalVisible(true);
  }

  const issueInvestorTokens = async (prop: {delegate: boolean}) => {
    if (!tokenConfigurationProcess?.isTokenSaleClosed)
      return notification.warning({
        message: 'Warning',
        description: 'You have close the Token Sale first',
      });

    if (!USDTokenPurchases?.find((data) => data['selected']))
      return notification.warning({
        message: 'Warning',
        description: 'Select Items',
      });

    const selectedInvestorsData = USDTokenPurchases.filter(
        (data) => data['selected']
    );

    console.log(selectedInvestorsData);

    const decimals = +(symbolDetailsAndSTData?.securityTokenData
        .decimals as string);

    const _from: string[] = [];
    const amount: string[] = [];

    selectedInvestorsData.forEach((data) => {
      const value = new BigNumber(data.amount)
          .times(new BigNumber(10).pow(decimals))
          .decimalPlaces(0)
          .toString(10);

      amount.push(value);
      _from.push(data.wallet);
    });

    console.log('_from', _from);
    console.log('amount', amount);

    if (amount.find((_amount) => !new BigNumber(_amount).isGreaterThan(0)))
      return;

    setIsModalVisible(true);
    setTransactions([{ details: 'Issuing tokens', submitting: true }]);

    try {
      const receipt = await mainFacet.addInvestorLockedAmountMulti(
          symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
          selectedWallet as string,
          tokenConfigurationProcess?.lockPeriod as number,
          _from,
          amount,
          {delegate: prop.delegate}
      );

      console.log(receipt);

      setTransactions((prev) => {
        const current = sharedService.clone(prev);
        current[0].receipt = receipt;
        return current;
      });

      if (receipt?.status) {
        setUSDTokenPurchases(
            USDTokenPurchases.filter((data) => !data['selected'])
        );

        issuerTokenAdminService
            .markTokensAsIssued({
              investorPaymentDetailsIds: selectedInvestorsData.map(
                  (_USDTokenPurchase) => _USDTokenPurchase._id
              ),
            })
            .catch(console.error);

        notification.success({
          message: 'Success',
          description: 'Tokens Issued Successfully',
        });
      }
    } catch (err) {
      console.error(err);
    }


    setTransactions((prev) => {
      const current = sharedService.clone(prev);
      current[0].submitting = false;
      return current;
    });
  };

  const issueETHInvestorTokens = async () => {
    // if (!tokenConfigurationProcess?.isTokenSaleClosed)
    //   return notification.warning({
    //     message: 'Warning',
    //     description: 'You have close the Token Sale first',
    //   });
    // if (!ETHTokenPurchases?.find((data) => data['selected']))
    //   return notification.warning({
    //     message: 'Warning',
    //     description: 'Select Items',
    //   });
    // const selectedInvestorsData = ETHTokenPurchases.filter(
    //   (data) => data['selected']
    // );
    // console.log(selectedInvestorsData);
    // const decimals = +(symbolDetailsAndSTData?.securityTokenData
    //   .decimals as string);
    // const indexArr: string[] = [];
    // const amount: string[] = [];
    // selectedInvestorsData.forEach((data) => {
    //   const value = new BigNumber(data.amount)
    //     .times(new BigNumber(10).pow(decimals))
    //     .decimalPlaces(0)
    //     .toString(10);
    //   amount.push(value);
    //   indexArr.push(data.index);
    // });
    // console.log('indexArr', indexArr);
    // console.log('amount', amount);
    // if (amount.find((_amount) => !new BigNumber(_amount).isGreaterThan(0)))
    //   return;
    // setIsModalVisible(true);
    // setTransactions([{ details: 'Issuing tokens', submitting: true }]);
    // try {
    //   const receipt = await securityTokenService.issueInvestorTokens(
    //     symbolDetailsAndSTData?.securityTokenData.contractAddress as string,
    //     selectedWallet as string,
    //     tokenConfigurationProcess?.lockPeriod as number,
    //     indexArr,
    //     amount
    //   );
    //   console.log(receipt);
    //   setTransactions((prev) => {
    //     const current = sharedService.clone(prev);
    //     current[0].receipt = receipt;
    //     return current;
    //   });
    //   if (receipt?.status) {
    //     setETHTokenPurchases(
    //       ETHTokenPurchases.filter((data) => !data['selected'])
    //     );
    //     notification.success({
    //       message: 'Success',
    //       description: 'Tokens Issued Successfully',
    //     });
    //   }
    // } catch (err) {
    //   console.error(err);
    // }
    // setTransactions((prev) => {
    //   const current = sharedService.clone(prev);
    //   current[0].submitting = false;
    //   return current;
    // });
  };

  const closeSale = async () => {
    setClosingSale(true);

    try {

      const _symbolDetailsAndSTData =
          await securityTokenRegisteryService.getSymbolDetailsAndSTData(
              tokenConfigurationProcess?.tokenSymbol as string
          );
      console.log("_symbolDetailsAndSTData", _symbolDetailsAndSTData)

      const [totalRaisedRes] = await Promise.all([
        issuerTokenAdminService.getTotalRaised({
          tokenConfigurationProcessId: tokenConfigurationProcess?._id!,
        }),
      ]);

      const {totalRaised, amountToRaise} = totalRaisedRes.data as {
        totalRaised: number;
        totalTransactions: number;
        amountToRaise: number;
      };

      const _isGoalReached = new BigNumber(totalRaised).gte(amountToRaise);

      console.log({
        amountToRaise,
        totalRaised,
        _isGoalReached,
      });
      const receipt = await mainFacet.closeTokenSale(
          _symbolDetailsAndSTData.securityTokenData.contractAddress,
          selectedWallet as string,
          _isGoalReached,
          {delegate: false}
      );

      if (receipt?.status) {
        const response = await tokenConfigurationService.closeSale({
          tokenConfigurationProcessId: tokenConfigurationProcess?._id as string,
        });

        if (response.success) {
          notification.success({
            message: 'Success',
            description: 'Token Sale closed Successfully',
          });

          setTokenConfigurationProcess((prev) => {
            const current = sharedService.clone(
                prev
            ) as TokenConfigurationProcess;
            current.isTokenSaleClosed = true;
            current.dateTokenSaleClosed = Date.now();

            return current;
          });

          setIsCloseSaleModalVisible(false);
        } else {
          notification.error({
            message: response.error.message,
            description: 'An error has ocurred. Try again later',
          });
        }
      }else{
        notification.success({
          message: 'Failed',
          description: 'Token Sale closed failed',
        });
      }
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Error',
        description: 'An error has ocurred. Try again later',
      });
    }

    setClosingSale(false);
  };

  // const updateUSDPrice = (value: string) => {
  //   if(new BigNumber(value).isGreaterThan(0)) return setUSDPrice(value);
  //   if(!value) return setUSDPrice('');
  // }

  return (
      <>
        <br />
        <br />
        <Row justify="center">
          <Col span={24}>
            {loading && (
                <div style={{ textAlign: 'center' }}>
                  <br />
                  <Spin size="large" />
                </div>
            )}

            {!loading && (
                <Card>
                  <Title level={1} style={{ textAlign: 'center' }}>
                    Issue your Security Tokens for{' '}
                    {
                      sharedService.regOptions.find(
                          (opt) => opt.shortValue === lastLaunchedReg
                      )?.name
                    }
                  </Title>

                  {!symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                      <>
                        <Result
                            title={`Security Token not deployed`}
                            subTitle="You have to deploy your Security Token first"
                            extra={
                              <Button
                                  key="console"
                                  onClick={() => history.push('/token-configurations')}
                              >
                                Create Token
                              </Button>
                            }
                        />
                      </>
                  )}

                  {symbolDetailsAndSTData?.symbolDetails.isDeployed && (
                      <>
                        <Title level={2} style={{ textAlign: 'center' }}>
                          Date of Launch:{' '}
                          {moment(
                              +regulationsLaunched[regulationsLaunched.length - 1]
                                  .dateLaunched * 1000
                          ).format('LLL')}
                        </Title>

                        {/* <div style={{textAlign:'center'}}>
                    <Title level={3}>Token Price</Title>

                    <p style={{display:'inline'}}>1 USD = </p>
                    <Input value={USDPrice} onChange={e => updateUSDPrice(e.target.value)} placeholder='Price' style={{display:'inline-block', width: '110px', margin: '0 10px'}}/>
                    <br/><br/>
                  </div> */}
                        {/* <p style={{display:'inline'}}>{symbolDetailsAndSTData.securityTokenData.symbol}</p> */}

                        {selectedWallet?.toLowerCase() !==
                            symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (
                                <WrongMetamaskWalletWarning
                                    address={symbolDetailsAndSTData.symbolDetails.owner.toLowerCase()}
                                />
                            )}

                        {selectedWallet?.toLowerCase() ===
                            symbolDetailsAndSTData.symbolDetails.owner.toLowerCase() && (
                                <>
                                  <Tabs
                                      defaultActiveKey="1"
                                      style={{ textAlign: 'center' }}
                                      type="card"
                                      size="large"
                                      tabBarExtraContent={
                                        <>
                                          {!tokenConfigurationProcess?.isTokenSaleClosed && (
                                              <Button
                                                  size="large"
                                                  style={{ marginBottom: '10px' }}
                                                  type="primary"
                                                  onClick={() => setIsCloseSaleModalVisible(true)}
                                              >
                                                CLOSE TOKEN SALE
                                              </Button>
                                          )}
                                          {tokenConfigurationProcess?.isTokenSaleClosed && (
                                              <div style={{ fontWeight: 'bold' }}>
                                                Sale Closed on{' '}
                                                {moment(
                                                    tokenConfigurationProcess.dateTokenSaleClosed
                                                ).format('LLL')}
                                              </div>
                                          )}
                                        </>
                                      }
                                  >
                                    <TabPane tab="USD" key="1">
                                      <Table
                                          scroll={{ x: true }}
                                          columns={investorColums}
                                          dataSource={USDTokenPurchases}
                                          pagination={false}
                                          rowKey="_id"
                                          rowSelection={{
                                            type: 'checkbox',
                                            selectedRowKeys: USDTokenPurchases?.filter(
                                                (data) => data['selected'] && !data.issued && data.wallet
                                            ).map((data) => data._id),
                                            onChange: (selectedRowKeys, selectedRows) => {
                                              selectInvestorsFromTable(selectedRows);
                                              console.log(
                                                  `selectedRowKeys: ${selectedRowKeys}`,
                                                  'selectedRows: ',
                                                  selectedRows
                                              );
                                            },
                                            getCheckboxProps: (record) => ({
                                              disabled: record.issued === true || !record.wallet,
                                              name: record._id,
                                            }),
                                          }}
                                      />

                                      <div style={{ textAlign: 'right' }}>
                                        <br />
                                        <Button
                                            size="large"
                                            type="primary"
                                            onClick={openTxFeeDelegationModal}
                                        >
                                          ISSUE TOKENS FOR INVESTORS
                                        </Button>
                                      </div>
                                    </TabPane>

                                    <TabPane disabled tab="ETH" key="2">
                                      <Table
                                          scroll={{ x: true }}
                                          columns={ETHColums}
                                          dataSource={ETHTokenPurchases}
                                          pagination={false}
                                          rowKey="index"
                                          rowSelection={{
                                            type: 'checkbox',
                                            selectedRowKeys: ETHTokenPurchases?.filter(
                                                (data) => data['selected']
                                            ).map((data) => data.index),
                                            onChange: (selectedRowKeys, selectedRows) => {
                                              selectETHInvestorsFromTable(selectedRows);
                                              console.log(
                                                  `selectedRowKeys: ${selectedRowKeys}`,
                                                  'selectedRows: ',
                                                  selectedRows
                                              );
                                            },
                                          }}
                                      />

                                      <div style={{ textAlign: 'right' }}>
                                        <br />
                                        <Button
                                            size="large"
                                            type="primary"
                                            onClick={issueETHInvestorTokens}
                                        >
                                          ISSUE TOKENS FOR INVESTORS
                                        </Button>
                                      </div>
                                    </TabPane>
                                  </Tabs>
                                </>
                            )}
                      </>
                  )}
                </Card>
            )}
          </Col>
        </Row>

        <TxFeeDelegationModal
            isVisible={isDelegationModalVisible}
            onSubmit={({delegate}) => {
              setIsDelegationModalVisible(false);
              issueInvestorTokens({delegate}).then();
            }}
            onCancel={() => setIsDelegationModalVisible(false)}
        />

        <TransactionModal
            title={'Token issue'}
            transactions={transactions}
            isModalVisible={isModalVisible}
            closeModal={() => setIsModalVisible(false)}
        />

        <Modal
            title="Close Token Sale"
            visible={!!isCloseSaleModalVisible}
            okText="Yes"
            cancelText="No"
            onCancel={() => setIsCloseSaleModalVisible(false)}
            onOk={closeSale}
            okButtonProps={{ loading: closingSale }}
        >
          <p>Are you sure you want to close the Token Sale?</p>
        </Modal>
      </>
  );
}
