import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import apex from "../../assets/images/logo/apext.png";
import login_icon from "../../assets/images/icons/login_icon.png";
import Config from "../../configure";
import "./index.css";
import { AuthService } from '../../../src/components/Shared/Auth.service';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navMenuMobile: false,
      scroll: 0,
      top: 0,
      height: 0,
      userInfo: null,
    };
    this.scrollToSection = this.scrollToSection.bind(this);
  }

  toggleNavMenu = () => {
    this.setState({ navMenuMobile: !this.state.navMenuMobile });
  };

  async componentDidMount() {
    this.fallBack();
    this.mount = true;
    const el = document.querySelector(".gc_main_menu_wrapper");
    this.setState({ top: el.offsetTop + 300, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);

    const authService = new AuthService();
    const userInfo = await authService.fetchUserInfo();
    this.setState({ userInfo });
  }

  componentWillUnmount() {
    this.mount = false;
    window.removeEventListener("scroll", this.handleScroll);
  }

  fallBack(){
    const sectionId = sessionStorage.getItem("targetSection");
    if (sectionId) {
      setTimeout(() => {
        const section = document.getElementById(sectionId);
        if (section) {
          window.scrollTo({
            top: section.offsetTop - 90,
            behavior: "smooth",
          });
        }
        sessionStorage.removeItem("targetSection");
      }, 100);
  }
}
  handleScroll = () => {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  };

  scrollToSection(event, sectionId) {
    event.preventDefault();

    if (window.location.pathname !== '/') {
      sessionStorage.setItem("targetSection", sectionId);
      window.location.href = '/';
    } else {
      this.scrollToTargetSection(sectionId);
    }
  }

scrollToTargetSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop - 90,
        behavior: "smooth",
      });
    }
}


  render() {
    const navigation = (
      <ul>
        <li>
          <a className="nav-link" href="/" style={{ fontWeight: "bolder" }}>
            Home
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#offering"
            onClick={(e) => this.scrollToSection(e, "offering")}
          >
            Offering
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#invTarget"
            onClick={(e) => this.scrollToSection(e, "invTarget")}
          >
            Tokens
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#project"
            onClick={(e) => this.scrollToSection(e, "project")}
          >
            Companies
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#platforms"
            onClick={(e) => this.scrollToSection(e, "platforms")}
          >
            Platforms
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#brands"
            onClick={(e) => this.scrollToSection(e, "brands")}
          >
            Brands
          </a>
        </li>
        <li>
          <a
            className="nav-link"
            style={{ fontWeight: "bolder" }}
            href="#about"
            onClick={(e) => this.scrollToSection(e, "about")}
          >
            About Us
          </a>
        </li>
      </ul>
    );

    return (
      <div id="default" className="wd_scroll_wrap wd_scroll">
        <header
          className={`gc_main_menu_wrapper ${
            this.state.scroll > this.state.top && window.innerWidth > 760
              ? "menu_fixed animated fadeInDown"
              : ""
          }`}
        >
          <Container fluid>
            <Row className="nav_header_wrapper">
              <Col className="col-xs-12 new-col logo-area-wrapper" sm={12} md={3} lg={3}>
                <div className="logo-area">
                  <Link to={Config.defaultPath}>
                    <img src={apex} style={{ height: "60px", marginLeft: "40px" }} alt="logo" />
                    <span className="company_name">Apex Defi Labs</span>
                  </Link>
                  <div className="menu-area" style={{ display: "flex" }}>
                    <div id="register" className="login-btn">
                      <Link
                        to="/account/login"
                        className="primary-bttn bttn-warning"
                      >
                        <img src={login_icon} alt="" />
                        <span>Login</span>
                      </Link>
                    </div>
                    <div id="login" className="login-btn">
                      <Link
                        to="/account/register/investor"
                        className="register-bttn bttn-primary"
                      >
                        <img src={login_icon} alt="" />
                        <span>Register</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={9} md={9} sm={12} className="col-xs-6 new-col navs_list hidden-xs">
                <div className="menu-area hidden-xs">
                  <nav className="wd_single_index_menu btc_main_menu">
                    {navigation}
                  </nav>

                  <div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                      to="/account/login"
                      className="primary-bttn bttn-warning"
                    >
                      <img src={login_icon} alt="" />
                      <span>Login</span>
                    </Link>
                  </div>
                  {!this.state.userInfo && <><div className="login-btn animate__animated animate__bounceInDown animate__slower">
                    <Link
                       to="/account/register/investor"
                       className="register-bttn bttn-primary"
                    >
                      <img src={login_icon} alt="" />
                      <span>Register</span>
                    </Link>
                  </div></>}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
    );
  }
}

export default Header;
