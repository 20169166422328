import { Button, Col, Row } from "antd";
import React, { useEffect } from "react";
import { Link } from "react-feather";
import { Container } from "reactstrap";
import sign from "../../assets/images/icons/tick.png";

const Disclaimer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  return (
    <div id="disclaimer" style={{ paddingTop: "220px", textAlign: "justify" }}>
      <Container>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#141838", fontSize: "55px", fontWeight: "bold",paddingBottom:'40px' }}>
            <span className="underline">NOTICE TO INVESTORS</span>
          </p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="tick" style={{ border: "1px solid",padding:"9px "}}>
              <div style={{ padding: "15px" }}></div>
              <li style={{ paddingBottom: "20px" }}>
                Under Regulation D: 
                 (1) The securities may be sold only to accredited investors, which for natural persons, are investors who meet certain minimum annual income or net worth thresholds;
                 (2) The securities are being offered in reliance on an exemption from the registration requirements of the Securities Act and are not required to comply with specific disclosure requirements that apply to registration under the Securities Act; 
                 (3) The Commission has not passed upon the merits of or given its approval to the securities, the terms of the offering, or the accuracy or completeness of any offering materials; 
                 (4) The securities are subject to legal restrictions on transfer and resale and investors should not assume they will be able to resell their securities; and 
                 (5) Investing in securities involves risk, and investors should be able to bear the loss of their investment.

              </li>
            </ul>
          </Col>
        </Row>
      </Container>
      <br/><br/><br/>
      <Container>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "#141838", fontSize: "55px", fontWeight: "bold",paddingBottom:'10px' }}>
            <span className="underline">Disclaimer</span>
          </p>
        </div>
        <Row>
          <Col className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <ul class="tick">
              <div style={{ padding: "15px" }}></div>

              <div style={{ padding: "15px" }}></div>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                The securities may be sold only to accredited investors, which for natural persons, are investors who meet certain minimum annual income or net worth thresholds; 
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
               The securities are being offered in reliance on an exemption from the registration requirements of the Securities Act and are not required to comply with specific disclosure requirements that apply to registration under the Securities Act; 
                </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                The Commission has not passed upon the merits of or given its approval to the securities, the terms of the offering, or the accuracy or completeness of any offering materials;
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              The securities are subject to legal restrictions on transfer and resale and investors should not assume they will be able to resell their securities; and 
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                Investing in securities involves risk, and investors should be able to bear the loss of their investment.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>  No Public Market:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Investors should acknowledge that there is no public market for the securities, and the platform does not intend to develop one.</span>
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>Long-Term Investment:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Investors should confirm that they are prepared for a long-term investment, given the illiquid nature of the securities.</span>
              
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>Token Representation:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Since you're dealing with tokenized securities, you may need to include a clause clarifying that the tokens represent ownership in the underlying securities and that any failure of the token (technical, security, or otherwise) does not absolve them of their ownership responsibilities.</span>
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>Digital Asset Risk:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Add a disclaimer regarding the risks involved with digital assets, including cybersecurity threats, potential loss of access to the tokens, and regulatory risks associated with tokenized securities.</span>
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}> Regulatory Changes:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>A clause that warns about possible changes in regulatory environments which could affect the holding or transferability of the tokenized securities.</span>
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}> Investor Sophistication:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Investors should affirm that they are sophisticated enough to understand the nature of this investment, including the use of blockchain technology and tokenized securities.</span>
              </li>

              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>Acknowledgment of KYC/AML Compliance:</span>
               <br/>
               <span style={{ marginLeft:"54px"}}>Investors should affirm their agreement to comply with KYC (Know Your Customer) and AML (Anti-Money Laundering) requirements.</span>
              </li>
              {/* <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                PROSPECTIVE INVESTORS SHOULD MAKE THEIR OWN INVESTIGATIONS AND
                EVALUATIONS OF CRYPTO ASSET RATING, INCLUDING THE MERITS AND
                RISKS INVOLVED IN AN INVESTMENT IN CRYPTO ASSET RATING.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                <p>
                  THE TOKEN HAS NOT BEEN REGISTERED UNDER THE U.S. SECURITIES
                  ACT OF 1933, AS AMENDED (THE “SECURITIES ACT”), AND,
                  ACCORDINGLY, MAY NOT BE OFFERED, SOLD, PLEDGED, OR OTHERWISE
                  TRANSFERRED WITHIN THE UNITED STATES OR TO, OR FOR THE ACCOUNT
                  OR BENEFIT OF, U.S. PERSONS, EXCEPT AS SET FORTH IN THE
                  OFFERING DOCUMENTS, AND AS PERMITTED BY APPLICABLE 
                  FEDERAL AND STATE SECURITIES LAWS.
                </p>
                <p>
                  PURSUANT TO THE REDEMPTION DESCRIBED IN THE OFFERING
                  MEMORANDUM; OR (D) PURSUANT TO AN EFFECTIVE REGISTRATION
                  STATEMENT UNDER THE SECURITIES ACT AND, IN EACH CASE, IN
                  ACCORDANCE WITH APPLICABLE STATE AND LOCAL SECURITIES LAWS,
                  AND
                </p>
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                CRYPTO ASSET RATING WILL NOT PROVIDE RATINGS FOR ANY TOKENS
                WHICH ARE LABELED OR MARKED AS A "CRYPTOCURRENCY" OR FOR ANY
                TOKEN WHICH IS FULLY DECENTRALIZED. CRYPTO ASSET RATING MAY
                DECLINE TO PROVIDE RATINGS FOR A PARTICULAR DIGTIAL ASSET IN ITS
                SOLE DISCRETION.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                CRYPTO ASSET RATING IS NOT A BROKER-DEALER OR PLACEMENT AGENT.
                AT NO TIME DOES CRYPTO ASSET RATING OFFER, BROKER, ADVISE,
                PURCHASE, SELL OR OTHERWISE TRANSACT IN SECURITIES REGULATED BY
                THE SEC OR FEDERAL OR STATE LAW. CRYPTO ASSET RATING IS NOT A
                PERSONAL FINANCIAL ADVISOR. CRYPTO ASSET RATING, WHETHER THROUGH
                THE WEBSITE OR OTHERWISE, DOES NOT PROVIDE PERSONAL FINANCIAL
                ADVICE, LOANS OR CREDIT, BANKING, CONSUMER CREDIT RATINGS,
                CREDIT DECISIONS, FINANCIAL PRODUCTS, BROKERAGE ACCOUNTS,
                INSURANCE, TAX ADVICE, LEGAL ADVICE, OR FINANCIAL OR LEGAL
                SERVICES OF ANY KIND. CRYPTO ASSET RATING MAY TAKE A STANDARD FEE
                FOR FEATURING A CRYPTO ASSET ON THE PLATFORM. HOWEVER, SUCH A FEE
                DOES NOT HAVE ANY EFFECT ON THE RATING PROVIDED FOR SUCH CRYPTO
                RATING. EVEN IF FEATURED ON THE PLATFORM, UNLESS EXPRESSLY
                STATED OTHERWISE, WE DO NOT PROVIDE ENDORSEMENT TO ANY CAPITAL
                OR INVESTMENT OPPORTUNITY, INITIAL COIN OFFERING, TOKENS OR ANY
                OTHER DIGITAL ASSET.
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                For more information, please read our{" "}
                <a href={require("../../assets/Documents/CAR - Reg. S Token Subscription Agreement.pdf")} 
                target="_blank" download>Token Subscription Agreement</a>
              </li>
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
                For more information, please read{" "}
                <a href={require("../../assets/Documents/CAR - PPM.pdf")} 
                target="_blank" download>Private Placement Memorandum(PPM)</a>
              </li> */}
              
              <li style={{ paddingBottom: "20px" }}>
                <img
                  src={sign}
                  style={{ height: "30px", width: "30px", marginRight: "20px" }}
                />
              <span style={{fontWeight:"bold" }}>By signing below, I acknowledge that:</span>
              </li>
              <div style={{ marginLeft: "54px", paddingBottom: "10px" }}>
                <ol style={{ paddingLeft: "20px" }}>
                  <li>I have read and understood the terms and conditions of this offering, including the risks associated with investing in securities offered under Regulation D.</li>
                  <li>I confirm that I am an accredited investor as defined by Regulation D and meet the necessary income or net worth thresholds.</li>
                  <li>I understand that the securities being offered are subject to legal restrictions on transfer and resale, and I should not assume I will be able to resell these securities.</li>
                  <li>I acknowledge that investing in securities involves significant risk, and I am capable of bearing the potential loss of my entire investment.</li>
                  <li>I understand that the tokens represent ownership in the underlying securities and are subject to risks inherent in digital assets.</li>
                  <li>I have conducted my own independent evaluation of this offering and understand the long-term nature of the investment.</li>
                  <li>I agree to comply with all KYC and AML regulations as required by the platform.</li>
                </ol>
              </div>
              <div style={{ padding: "30px",borderBottom:'1px solid' }}></div>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Disclaimer;
