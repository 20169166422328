import React, { useState, useEffect } from 'react';
import CardHOC from "../../CardHOC";
import {AuthService} from "../../Shared/Auth.service";
import { Spin, Table} from "antd";
import moment from "moment";
import { PlatformIssuerAdminService } from '../../PlatformIssuerAdmin/PlatformIssuerAdmin.service';
import { PlatformComplianceAdminService } from '../PlatformComplianceAdmin.service';

const plateformService = new PlatformComplianceAdminService();
const useUserContext = () => new AuthService().useUserContext();

export default function() {
  const {userInfo} = useUserContext();

  const [loading, setLoading] = useState<boolean>(false);
  const [invites, setInvites] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalInvites, setTotalInvites] = useState(0);

  const fetchData = async (page, size) => {
    if (!userInfo) return;

    setLoading(true);
    const response = await plateformService.getInvites(page, size);
    if (response.data) {
      setInvites(response.data.users);
      setTotalInvites(response.data.totalCount);
    }
    setLoading(false);
  }

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, [userInfo, currentPage, pageSize]);


  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      render: (value)=>value
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      render: (value)=>value
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (value)=>value
    },
    {
      title: 'Phone Number',
      dataIndex: 'contactNumber',
      render: (value)=>value
    },
    {
      title: 'Created Date',
      dataIndex: 'creationTS',
      render: (value: number) => moment(value).format('lll')
    },
  ];

  return (
    <CardHOC
      heading={'Registered Investors List'}
      component={<>

        {loading &&
          <div style={{textAlign:'center'}}>
            <br/><br/>
            <Spin size='large'/>
          </div>
        }

        {!loading &&
          <>
            <Table
              columns={columns}
              dataSource={invites}
              rowKey='_id'
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalInvites,
                showSizeChanger: true,
                pageSizeOptions: ['10', '20', '30', '50'],
              }}
              onChange={handleTableChange}
            />
          </>
        }
      </>}
    />
  );
}